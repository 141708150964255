import React, {
  FC,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styles from './ApplicationRightPanelUsers.module.css';
import clsx from 'clsx';
import {
  TUser,
  useLazyGetUserCountQuery,
  useLazyGetUsersQuery,
} from '../../redux/services/client';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TAppSlice } from '../../redux/appSlice';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as RemoveIcon } from '../../icons/Close.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as OwnerIcon } from '../../icons/Owner.svg';
import { AutoSizer, Column, InfiniteLoader, Table, TableHeaderProps } from 'react-virtualized';
import { debounce } from '@mui/material';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getImageURL, getRoleName, isObjectEmpty } from '../../helpers';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { Link } from 'react-router-dom';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { ChangePermissionsModal } from './ChangePermissionsModal';

export type Order = 'asc' | 'desc';
export type TColumnNames = 'mainPage' | 'user' | 'role';
export type TApplicationsTableProps = {
  selectedClientId: TAppSlice['selectedClientId'];
  panelRef: RefObject<HTMLDivElement>;
  loggedUserId?: string;
};

const mapStateToProps = (state: RootState) => ({
  selectedClientId: state.app.selectedClientId,
  loggedUserId: state.user.userProfile.id,
});

const ApplicationsRightPanelUsersComponent: FC<TApplicationsTableProps> = ({
  selectedClientId,
  panelRef,
  loggedUserId,
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [selectedUserToActions, setSelectedUserToActions] = useState<Partial<TUser> | null>(null);
  const [selectedPermission, setSelectedPermission] = useState<null | string>(null);
  const [orderBy, setOrderBy] = useState<TColumnNames>('user');
  const [changePermissionsModalOpen, setChangePermissionsModalOpen] = useState(false);
  const [users, setUsers] = useState<({ user: Partial<TUser>; role: string } | undefined)[]>([]);
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverOriginTop, setPopoverOriginTop] = useState(false);
  const [searchIconRight, setSearchIconRight] = useState(7);
  const [toggleUpdateUsers, setToggleUpdateUsers] = useState(false);
  const isOpen = Boolean(anchorEl);
  const rowCount = users.length;
  const [getUsers] = useLazyGetUsersQuery();
  const [getUserCount] = useLazyGetUserCountQuery();
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);

  useEffect(() => {
    setSearchIconRight(7);
  }, [searchValue, selectedClientId, isSearchInputOpen]);

  useEffect(() => {
    if (users.every((user) => user === undefined))
      infiniteLoaderRef.current?.resetLoadMoreRowsCache(true);
  }, [users]);

  useEffect(() => {
    const setUsersOnStart = async () => {
      const { data: dataCount } = await getUserCount({
        selectedAppId: selectedClientId || '',
        search_string: '',
      });

      setSearchValue('');
      setUsers(new Array(Number(dataCount?.userCount)).fill(undefined));
    };
    setUsersOnStart();
    return () => setUsers([]);
  }, [selectedClientId, toggleUpdateUsers]);

  useLayoutEffect(() => {
    const grid = document.getElementsByClassName('users-table__grid')?.[0];
    const preventDefault = (e: Event) => {
      if ((panelRef.current?.scrollTop || 0) < 150) e.preventDefault();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const scrollPanel = (e: any) => {
      const deltaY = e.deltaY;
      if ((panelRef.current?.scrollTop as number) < 150) {
        panelRef.current?.scrollBy({
          top: deltaY * 10,
          behavior: 'smooth',
        });
      } else if (grid.scrollTop === 0) {
        panelRef.current?.scrollBy({
          top: deltaY * 10,
          behavior: 'smooth',
        });
      }
    };
    grid?.addEventListener('wheel', preventDefault, { passive: false });
    grid?.addEventListener('wheel', scrollPanel);
    return () => {
      grid?.removeEventListener('wheel', preventDefault);
      grid?.removeEventListener('wheel', scrollPanel);
    };
  }, []);

  const onSearch = async (searchString: string) => {
    const { data: dataCount } = await getUserCount({
      selectedAppId: selectedClientId || '',
      search_string: searchString,
    });
    if (dataCount !== undefined) {
      setUsers(new Array(Number(dataCount.userCount)).fill(undefined));
    }
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 200), [selectedClientId, order, orderBy]);

  const rowGetter = ({ index }: { index: number }) => {
    return users?.[index] || {};
  };

  const isRowLoaded = ({ index }: { index: number }) => !!users[index];

  const loadMoreRows = async ({
    startIndex,
    stopIndex,
    searchString,
  }: {
    startIndex: number;
    stopIndex: number;
    searchString?: string;
  }) => {
    const { data } = await getUsers({
      client_id: selectedClientId || '',
      number_of_records: String(stopIndex - startIndex + 1),
      number_of_skip: String(startIndex),
      sort_by: orderBy === 'user' ? 'nickname' : orderBy,
      sort_direction: order,
      search_string: searchString === undefined ? searchValue : searchString,
    });

    setUsers((users) =>
      users.map((user, index) => {
        if (index < startIndex || index > stopIndex) return user;
        return data?.[index - startIndex];
      }),
    );
  };

  const getRowClassName = ({ index }: { index: number }) => {
    return clsx(styles.row, {
      [styles['content-row']]: index !== -1,
      [styles['header-row']]: index === -1,
    });
  };

  const handleRequestSort = async (property: TColumnNames) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setUsers(new Array(Number(users.length)).fill(undefined));
  };

  const onRef = (node: HTMLButtonElement) => {
    if (searchIconRight === 7)
      setSearchIconRight(window.innerWidth - node?.getBoundingClientRect().right);
  };

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>, user: Partial<TUser>) => {
    event.stopPropagation();
    if (event.clientY + 180 > document.body.clientHeight) setPopoverOriginTop(true);
    else setPopoverOriginTop(false);
    setSelectedUserToActions(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedUserToActions(null);
  };

  const closeChangePermissionModal = () => {
    setSelectedPermission(null);
    setChangePermissionsModalOpen(false);
  };

  const headerRenderer = ({ label, dataKey }: TableHeaderProps & { columnIndex: number }) => {
    return (
      <TableCell
        component="div"
        className={clsx(styles['table-cell'], styles['flex-container'], styles['header-cell'])}
        variant="head"
        align="left"
      >
        <Button
          onClick={() => handleRequestSort(dataKey as TColumnNames)}
          className={styles['header-button']}
        >
          <Typography className={clsx('text-14', 'color-858BA0')}>{label}</Typography>
        </Button>
        {dataKey === orderBy && (
          <ArrowTopIcon className={clsx({ [styles['rotate-180']]: !(order === 'asc') })} />
        )}
      </TableCell>
    );
  };

  return (
    <div style={{ height: 'calc(100vh - 600px)' }}>
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
      >
        {({ onRowsRendered, registerChild }) => {
          return (
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <Table
                    gridClassName={'users-table__grid'}
                    id={'table'}
                    height={height + 306}
                    width={width}
                    rowHeight={80}
                    headerHeight={56}
                    rowCount={rowCount}
                    rowGetter={rowGetter}
                    rowClassName={getRowClassName}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                  >
                    <Column
                      label="Имя"
                      width={width * 0.5 - 30}
                      key="name"
                      headerRenderer={(headerProps) =>
                        headerRenderer({
                          ...headerProps,
                          columnIndex: 0,
                        })
                      }
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <TableCell
                            className={clsx(styles['table-cell'], styles['flex-container'])}
                            component="div"
                            variant="body"
                            align="left"
                          >
                            <Typography
                              style={{ maxWidth: width * 0.5 - 50 }}
                              className={clsx(
                                styles['overflow-ellipsis'],
                                'text-14',
                                'color-4C6AD4',
                              )}
                            >
                              {isObjectEmpty(rowData) ? (
                                <Skeleton width={50} />
                              ) : (
                                <div className={styles['flex-container']}>
                                  <div
                                    className={styles['app-icon-wrapper']}
                                    style={{
                                      backgroundImage: `url(${getImageURL(cellData?.picture)})`,
                                    }}
                                  >
                                    {!cellData.picture && (
                                      <div className={styles['app-icon-default']}>
                                        {cellData?.nickname
                                          ?.split(' ')
                                          .map((name: string) => name[0]?.toUpperCase())
                                          .join('')}
                                      </div>
                                    )}
                                  </div>
                                  <div style={{ marginLeft: '12px' }}>
                                    <Typography
                                      className={clsx(
                                        'text-14',
                                        'color-4C6AD4',
                                        styles['overflow-ellipsis'],
                                        styles['user-link'],
                                      )}
                                      style={{ maxWidth: width * 0.5 - 110 }}
                                    >
                                      <Link
                                        style={{
                                          display: 'block',
                                          width: '100%',
                                          textDecoration: 'none',
                                        }}
                                        className={clsx('color-4C6AD4')}
                                        to={{
                                          pathname:
                                            Number(loggedUserId) === rowData?.user?.id
                                              ? '/profile'
                                              : `/application/user/${selectedClientId}/${rowData?.user?.id}`,
                                          state: { prevPath: location.pathname },
                                        }}
                                      >
                                        {(cellData.nickname || '').trim()
                                          ? cellData.nickname
                                          : (
                                              (cellData.given_name || '') +
                                              ' ' +
                                              (cellData.family_name || '')
                                            ).trim() || 'Нет имени'}
                                      </Link>
                                    </Typography>
                                    <Typography className={clsx('text-12', 'color-858BA0')}>
                                      ID {cellData.id}
                                    </Typography>
                                  </div>
                                </div>
                              )}
                            </Typography>
                          </TableCell>
                        );
                      }}
                      dataKey="user"
                    />
                    <Column
                      label="Права"
                      width={width * 0.5 - 30}
                      headerRenderer={(headerProps) =>
                        headerRenderer({
                          ...headerProps,
                          columnIndex: 1,
                        })
                      }
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <TableCell
                            className={clsx(styles['table-cell'], styles['flex-container'])}
                            component="div"
                            variant="body"
                            align="left"
                          >
                            <Typography
                              style={{ maxWidth: width * 0.5 - 40 }}
                              className={clsx(styles['overflow-ellipsis'], 'text-14')}
                            >
                              {isObjectEmpty(rowData) ? (
                                <Skeleton width={50} />
                              ) : (
                                getRoleName(cellData)
                              )}
                            </Typography>
                          </TableCell>
                        );
                      }}
                      dataKey="role"
                    />
                    <Column
                      headerRenderer={() => {
                        return (
                          <>
                            {isSearchInputOpen ? (
                              <ClickAwayListener onClickAway={() => setIsSearchInputOpen(false)}>
                                <div className={styles['search-wrapper']}>
                                  <TextField
                                    inputProps={{ className: styles.input }}
                                    InputProps={{
                                      className: styles['input-root'],
                                    }}
                                    variant="standard"
                                    value={searchValue}
                                    fullWidth
                                    onChange={(e) => {
                                      onSearchDebounce(e.target.value);
                                      setSearchValue(e.target.value);
                                    }}
                                  />
                                  <SearchIcon className={styles['search-icon']} />
                                  <IconButton
                                    onClick={() => {
                                      onSearch('');
                                      setSearchValue('');
                                    }}
                                    disabled={!searchValue}
                                    className={styles['delete-icon-button']}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </div>
                              </ClickAwayListener>
                            ) : (
                              <IconButton
                                onClick={() => setIsSearchInputOpen(true)}
                                className={styles['search-icon-button']}
                                style={{
                                  right: searchIconRight + 8,
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            )}
                          </>
                        );
                      }}
                      dataKey="key"
                      className={styles['actions-button-column']}
                      cellRenderer={({ rowData }) => (
                        <TableCell
                          component="div"
                          className={clsx(styles['table-cell'], styles['flex-container'])}
                          variant="head"
                          align="right"
                        >
                          <Popover
                            classes={{
                              paper: clsx(styles.paper, styles['popover-paper'], {
                                [styles['horizontal-direction-top']]: popoverOriginTop,
                              }),
                            }}
                            onClose={handleClosePopover}
                            anchorEl={anchorEl}
                            open={isOpen && rowData?.user.id === selectedUserToActions?.id}
                            anchorOrigin={{
                              vertical: popoverOriginTop ? 'top' : 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: popoverOriginTop ? 'bottom' : 'top',
                              horizontal: 'right',
                            }}
                          >
                            <Link
                              style={{ display: 'block', width: '100%', textDecoration: 'none' }}
                              to={{
                                pathname:
                                  Number(loggedUserId) === rowData?.user?.id
                                    ? '/profile'
                                    : `/application/user/${selectedClientId}/${rowData?.user?.id}`,
                                state: { prevPath: location.pathname },
                              }}
                            >
                              <CustomPopoverButton
                                startIcon={<AvatarIcon className={styles['action-button-icon']} />}
                              >
                                Посмотреть профиль
                              </CustomPopoverButton>
                            </Link>
                            <CustomPopoverButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setChangePermissionsModalOpen(true);
                                setAnchorEl(null);
                              }}
                              startIcon={<OwnerIcon className={styles['action-button-icon']} />}
                            >
                              Изменить полномочия
                            </CustomPopoverButton>
                            <CustomPopoverButton
                              startIcon={<QuitIcon className={styles['action-button-icon']} />}
                              onClick={async () => {
                                await deleteAllSession(rowData.user.id);
                                window.location.reload();
                              }}
                            >
                              Завершить все сеансы
                            </CustomPopoverButton>
                          </Popover>
                          <IconButton
                            ref={onRef}
                            onClick={(event) => handleOpenPopover(event, rowData?.user)}
                            className={clsx(styles['open-actions-icon'], {
                              [styles['active-actions-icon']]:
                                rowData?.user?.id === selectedUserToActions?.id && isOpen,
                            })}
                            style={{
                              position: 'absolute',
                              right: 8,
                            }}
                          >
                            <ActionsIcon />
                          </IconButton>
                        </TableCell>
                      )}
                      width={40}
                    />
                  </Table>
                );
              }}
            </AutoSizer>
          );
        }}
      </InfiniteLoader>
      <Modal open={changePermissionsModalOpen} onClose={closeChangePermissionModal}>
        <ChangePermissionsModal
          selectedClientId={selectedClientId}
          selectedUserToActions={selectedUserToActions}
          users={users}
          toggleUpdateUsers={toggleUpdateUsers}
          setToggleUpdateUsers={setToggleUpdateUsers}
          closeChangePermissionModal={closeChangePermissionModal}
          selectedPermission={selectedPermission}
          setSelectedPermission={setSelectedPermission}
          setChangePermissionsModalOpen={setChangePermissionsModalOpen}
        />
      </Modal>
    </div>
  );
};

export const ApplicationRightPanelUsers = connect(mapStateToProps)(
  ApplicationsRightPanelUsersComponent,
);
