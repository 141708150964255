import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// #427 import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UploadAndDisplayImage } from '../UploadAndDisplayImage';
import styles from './EditUserProfile.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../icons/ArrowDropDown.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as CheckIcon } from '../../icons/CheckMark.svg';
import MenuItem from '@mui/material/MenuItem';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { TCustomFields, TUserProfile } from '../../redux/userSlice';
import { updateUser, checkIdentifier } from '../../requests/user';
import { generateYearsBetween, isObjectEmpty } from '../../helpers';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { setIsModalOpen, setIsChangesUnsaved, setComponentPath } from '../../redux/appSlice';
import { TUser, useGetSettingsQuery, useLazyGetUsersQuery } from '../../redux/services/client';
import { CUSTOM_USER_FIELDS /* #427 PROJECT_NAME*/ } from '../../constants';
import { Roles } from '../../enums';

type Inputs = {
  given_name: string;
  picture: File | string | null;
  nickname: string;
  family_name: string;
  login: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
};

const schema = yup
  .object({
    nickname: yup
      .string()
      .max(71, 'Не может превышать 71 символ')
      .min(3, 'Не может быть меньше 3 символов')
      .matches(/^[^ ]+( *[^ ]+)*?$/, 'Не может содержать пробелы в начале и конце')
      .required('Обязательное поле'),
    given_name: yup
      .string()
      .max(50, 'Не может превышать 50 символов')
      .matches(
        /^([a-zA-Z0-9а-яА-ЯёЁ]+((\s|-|'|_|\.)[a-zA-Z0-9а-яА-ЯёЁ]+)*)?$/,
        "Имя может включать буквы (a-z) и (а-я),цифры (0-9) дефис (-),нижнее подчеркивание (_),апостроф ('), пробел и точку",
      ),
    family_name: yup
      .string()
      .max(50, 'Не может превышать 50 символов')
      .matches(
        /^([a-zA-Z0-9а-яА-ЯёЁ]+((\s|-|'|_|\.)[a-zA-Z0-9а-яА-ЯёЁ]+)*)?$/,
        "Фамилия может включать буквы (a-z) и (а-я),цифры (0-9) дефис (-),нижнее подчеркивание (_),апостроф ('), пробел и точку",
      ),
    login: yup
      .string()
      .min(3, 'Не может быть меньше 3 символов')
      .max(71, 'Не может превышать 71 символ')
      .matches(/^(?!\d+$).*$/, 'Логин не может состоять только из цифр')
      .matches(/^[^@]+$/, 'Логин не может содержать символ "@" ')
      .matches(/[^ ]+/, {
        message: 'Логин не может состоять только из пробелов',
      })
      // #584 .matches(/^[a-z0-9.]+$/, 'Может включать латинские буквы (a-z), цифры (0-9) и точку (.)')
      .required('Обязательное поле'),
  })
  .required();

const mapStateToProps = (state: RootState) => ({
  isModalOpen: state.app.isModalOpen,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
});

type TEditProfileComponent = {
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
};

const EditProfileAsAdminComponent: FC<TEditProfileComponent> = ({
  isModalOpen,
  isChangesUnsaved,
  componentPath,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
  } = useForm<Inputs & TCustomFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  // #427 const [isPublic, setIsPublic] = useState<boolean>(false);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const setAvatarValue = (value: File | null) => setValue('picture', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('picture', { message: error });
  const clearAvatarError = () => clearErrors('picture');
  const history = useHistory();
  const watchBirthDay = watch('birthDay');
  const watchBirthMonth = watch('birthMonth');
  const watchBirthYear = watch('birthYear');
  const [hasBirthdate, setHasBirthdate] = useState(false);
  const months = [
    { name: 'январь', days: 31 },
    { name: 'февраль', days: +watchBirthYear % 4 === 0 ? 29 : 28 },
    { name: 'март', days: 31 },
    { name: 'апрель', days: 30 },
    { name: 'май', days: 31 },
    { name: 'июнь', days: 30 },
    { name: 'июль', days: 31 },
    { name: 'август', days: 31 },
    { name: 'сентябрь', days: 30 },
    { name: 'октябрь', days: 31 },
    { name: 'ноябрь', days: 30 },
    { name: 'декабрь', days: 31 },
  ];
  const selectedMonth = months.find((month) => month.name === watchBirthMonth);
  const dispatch = useDispatch();
  const [getUsers] = useLazyGetUsersQuery();
  const { data: dataSettings } = useGetSettingsQuery();
  const { clientId, userId } = useParams<{ clientId: string; userId: string }>();
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: string } | null | undefined
  >(null);

  useEffect(() => {
    const start = async () => {
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: userId,
      });

      setSelectedUser(users?.find((user) => user.user.id === Number(userId)));
    };
    start();
  }, [clientId, userId]);

  useEffect(() => {
    setValue('birthDay', String(Math.min(+(selectedMonth?.days || ''), +watchBirthDay)));
  }, [watchBirthMonth, watchBirthYear]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field)) return field.some((elem) => elem.value);
        return field === true;
      });
    if (isChangesUnsaved !== isDirty) dispatch(setIsChangesUnsaved(isDirty));
  }, [Object.values(dirtyFields)]);

  useEffect(() => {
    (
      ['nickname', 'given_name', 'family_name', 'login'] as Array<
        keyof Omit<Inputs, 'birthDay' | 'birthMonth' | 'birthYear' | 'picture'>
      >
    ).forEach((field) => {
      if (selectedUser?.user[field]) setValue(field, selectedUser?.user[field] || '');
    });
    if (selectedUser?.user.birthdate) {
      const date = new Date(selectedUser.user.birthdate);
      setValue('birthYear', String(date.getFullYear()));
      setValue('birthMonth', months[date.getMonth()]?.name);
      setValue('birthDay', String(date.getDate()));
      setHasBirthdate(true);
    }
  }, [selectedUser?.user]);

  useEffect(() => {
    return () => {
      dispatch(setComponentPath('/profile'));
      setHasBirthdate(false);
    };
  }, []);

  const onSubmit: SubmitHandler<Inputs & TCustomFields> = async (data) => {
    let birthdate: null | Date = null;

    const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
      (
        acc: Partial<Omit<TUserProfile, 'picture'> & { picture: File | null } & TCustomFields>,
        field,
      ) => {
        if (field === 'birthDay' || field === 'birthYear' || field === 'birthMonth') {
          const month = months.findIndex(({ name }) => name === data.birthMonth);
          birthdate = new Date(Date.UTC(+data.birthYear, month, +data.birthDay));
          return acc;
        }

        if (field === 'picture') {
          if (typeof data.picture !== 'string') {
            acc.picture = data.picture;
            return acc;
          }

          return acc;
        }

        acc[field] = data[field];

        return acc;
      },
      {},
    );

    if ('login' in dirtyFields && selectedUser?.user.login !== data.login) {
      const isAvailable = await checkIdentifier(data.login);
      if (isAvailable) {
        setError('login', {
          type: 'api',
          message: 'Уже существует',
        });
        return;
      }
    }

    if (!userId) return;
    if (isDateError()) return;
    if (birthdate) payload.birthdate = (birthdate as unknown as Date).toISOString();

    await updateUser(payload, String(userId));
    history.push(`/application/user/${clientId}/${userId}`);
    dispatch(setIsChangesUnsaved(false));
  };

  const birthDate = new Date(
    +watchBirthYear,
    months.findIndex((month) => month.name === watchBirthMonth),
    +watchBirthDay,
  );
  const dateNow = new Date();
  const isDateError = () => {
    if (!dataSettings || !hasBirthdate) return false;
    if (dateNow.getFullYear() - birthDate.getFullYear() < dataSettings.min_age) return true;
    if (dateNow.getFullYear() - birthDate.getFullYear() === dataSettings.min_age) {
      if (dateNow.getMonth() < birthDate.getMonth()) return true;
      if (dateNow.getMonth() === birthDate.getMonth() && dateNow.getDate() <= birthDate.getDate())
        return true;
    }
    if (dateNow.getFullYear() - birthDate.getFullYear() > dataSettings.max_age) return true;
    if (dateNow.getFullYear() - birthDate.getFullYear() === dataSettings.max_age) {
      if (dateNow.getMonth() > birthDate.getMonth()) return true;
      if (dateNow.getMonth() === birthDate.getMonth() && dateNow.getDate() > birthDate.getDate())
        return true;
    }
    return false;
  };

  const relocation = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setIsChangesUnsaved(false));
    history.push(componentPath);
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={
          isChangesUnsaved
            ? () => {
                dispatch(setIsModalOpen(true));
                dispatch(setComponentPath(`/application/user/${clientId}/${userId}`));
              }
            : () => {
                dispatch(setComponentPath('/profile'));
                history.push(`/application/user/${clientId}/${userId}`);
              }
        }
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
        <div className={styles['padding-wrapper']}>
          <Typography
            className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
          >
            Редактировать профиль
          </Typography>
          <Typography
            className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
          >
            Основная информация
          </Typography>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Публичное имя
          </Typography>
          <TextField
            {...register('nickname', {
              onChange: () => {
                if (errors.nickname) clearErrors('nickname');
              },
            })}
            className={clsx('custom', styles.textfield)}
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.nickname}
            helperText={errors.nickname ? errors.nickname.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Имя
          </Typography>
          <TextField
            {...register('given_name', {
              onChange: () => {
                if (errors.given_name) clearErrors('given_name');
              },
            })}
            className={clsx('custom', styles.textfield)}
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.given_name}
            helperText={errors.given_name ? errors.given_name.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Фамилия
          </Typography>
          <TextField
            {...register('family_name', {
              onChange: () => {
                if (errors.family_name) clearErrors('family_name');
              },
            })}
            className={clsx('custom', styles.textfield)}
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.family_name}
            helperText={errors.family_name ? errors.family_name.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Логин
          </Typography>
          <TextField
            {...register('login', {
              onChange: () => {
                if (errors.login) clearErrors('login');
              },
            })}
            className={clsx('custom', styles.textfield)}
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.login}
            helperText={errors.login ? errors.login.message : ''}
            fullWidth
            variant="standard"
          />
          {CUSTOM_USER_FIELDS &&
            Object.keys(CUSTOM_USER_FIELDS)
              ?.filter(
                (key) => CUSTOM_USER_FIELDS[key]?.editable || selectedUser?.role === Roles.OWNER,
              )
              ?.map((key) => (
                <>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    {CUSTOM_USER_FIELDS[key]?.title}
                  </Typography>
                  <TextField
                    key={key}
                    {...register(key, {
                      onChange: () => {
                        if (errors[key]) clearErrors(key);
                      },
                    })}
                    className={clsx('custom', styles.textfield)}
                    FormHelperTextProps={{
                      className: clsx('text-14', 'color-858BA0'),
                    }}
                    error={!!errors[key]}
                    helperText={errors[key] ? errors[key]?.message : ''}
                    fullWidth
                    variant="standard"
                  />
                </>
              ))}
          <div>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Дата рождения
            </Typography>
            <div style={{ marginBottom: 24 }}>
              <div style={{ display: 'flex' }}>
                <Select
                  IconComponent={ArrowDropDownIcon}
                  MenuProps={{ classes: { paper: styles.menu } }}
                  className={styles.select}
                  classes={{ outlined: styles.outlined }}
                  sx={{
                    '&.Mui-focused fieldset': {
                      borderColor: '#606783 !important',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: '#FC545C !important',
                    },
                  }}
                  error={isDateError()}
                  value={watchBirthDay}
                  renderValue={(value) => `0${value}`.slice(-2)}
                  onChange={(e) => setValue('birthDay', e.target.value, { shouldDirty: true })}
                >
                  {new Array(selectedMonth?.days).fill(null).map((_, index) => (
                    <MenuItem
                      classes={{
                        root: styles['select-item'],
                        selected: styles['select-item-selected'],
                      }}
                      key={index}
                      value={index + 1}
                    >
                      {index + 1}
                      <CheckIcon className={styles['check-icon']} />
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  IconComponent={ArrowDropDownIcon}
                  MenuProps={{ classes: { paper: styles.menu } }}
                  className={styles.select}
                  classes={{ outlined: styles.outlined }}
                  sx={{
                    '&.Mui-focused fieldset': {
                      borderColor: '#606783 !important',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: '#FC545C !important',
                    },
                  }}
                  error={isDateError()}
                  value={watchBirthMonth}
                  onChange={(e) => {
                    setValue('birthMonth', e.target.value, { shouldDirty: true });
                  }}
                >
                  {months.map((month) => (
                    <MenuItem
                      classes={{
                        root: styles['select-item'],
                        selected: styles['select-item-selected'],
                      }}
                      key={month.name}
                      value={month.name}
                    >
                      {month.name}
                      <CheckIcon className={styles['check-icon']} />
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  IconComponent={ArrowDropDownIcon}
                  MenuProps={{ classes: { paper: styles.menu } }}
                  className={styles.select}
                  classes={{ outlined: styles.outlined }}
                  sx={{
                    '&.Mui-focused fieldset': {
                      borderColor: '#606783 !important',
                    },
                    '&.Mui-error fieldset': {
                      borderColor: '#FC545C !important',
                    },
                  }}
                  error={isDateError()}
                  value={watchBirthYear}
                  onChange={(e) => setValue('birthYear', e.target.value, { shouldDirty: true })}
                >
                  {generateYearsBetween(
                    new Date().getFullYear() - (dataSettings?.max_age || 120),
                    new Date().getFullYear() - (dataSettings?.min_age || 0),
                  ).map((year) => (
                    <MenuItem
                      classes={{
                        root: styles['select-item'],
                        selected: styles['select-item-selected'],
                      }}
                      key={year}
                      value={year}
                    >
                      {year}
                      <CheckIcon className={styles['check-icon']} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {isDateError() && (
                <Typography style={{ marginTop: 8 }} className={clsx('color-FC545C', 'sf-14-reg')}>
                  Дата рождения не соответствует возрастным ограничениям
                </Typography>
              )}
            </div>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Фото профиля
            </Typography>
            <UploadAndDisplayImage
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
              componentName="edit-profile"
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              defaultValue={selectedUser?.user.picture || null}
              setAvatarValue={setAvatarValue}
              DefaultIcon={<AvatarIcon title="Profile avatar"/>}
            />
            {errors.picture && (
              <Typography className={clsx('text-14', styles['input-error'])}>
                {errors.picture.message}
              </Typography>
            )}
            <Typography className={clsx('text-14', 'color-858BA0')}>
              Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
            </Typography>
          </div>
          <div className={styles['submit-buttons']}>
            <Button onClick={() => history.goBack()} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button className={styles['create-button']} type="submit" variant="custom">
              Сохранить
            </Button>
          </div>
        </div>
        {/* #427
        <div className={styles.divider} />
        <div className={styles['padding-wrapper']}>
          <div className={styles['switch-wrapper']}>
            <div>
              <Typography
                className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
              >
                Публичность
              </Typography>
              <Typography style={{ width: 420 }} className={clsx('text-14', 'color-858BA0')}>
                Ваши публичное имя, id и фото профиля станут видны в поиске другим пользователям
                {PROJECT_NAME}
              </Typography>
            </div>
            <Switch
              value={isPublic}
              onChange={() => setIsPublic((isPublic) => !isPublic)}
              disableRipple
            />
          </div>
        </div> */}
      </form>
      <Modal open={isModalOpen} onClose={() => dispatch(setIsModalOpen(false))}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton
              onClick={() => dispatch(setIsModalOpen(false))}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="custom"
              color="secondary"
              onClick={() => dispatch(setIsModalOpen(false))}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                relocation();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const EditProfileAsAdmin = connect(mapStateToProps)(EditProfileAsAdminComponent);
