import React, {
  Dispatch,
  FC,
  MouseEvent,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import styles from './ApplicationUserInfo.module.css';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { PublicStatusPopover } from '../profile/PublicStatusPopover';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ReactComponent as MailIcon } from '../../icons/Mail.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { RootState } from '../../redux/store';
import {
  AccountTypes,
  TExternalAccount,
  useDeleteExternalAccountMutation,
  useGetExternalAccountsQuery,
  useGetPrivateClaimsQuery,
  useSetMainEmailMutation,
} from '../../redux/services/user';
import { TUser, useLazyGetUsersQuery } from '../../redux/services/client';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { TAppSlice } from '../../redux/appSlice';
import { setUpdateExternalAccounts, TUserSlice, TUserProfile } from '../../redux/userSlice';
import {
  exportToJson,
  getImageURL,
  getMonthByNumber,
  isAdministrator,
  isEditor,
  isOwner,
  isOwnerOrEditor,
} from '../../helpers';
import { BACKEND_URL, CLIENT_ID } from '../../constants';
import { Roles } from '../../enums';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';

type TUseHistoryProps = {
  prevPath: string;
};

type TApplicationUserInfoComponent = {
  isMobile: TAppSlice['isMobile'];
  userRole?: string;
  updateExternalAccounts: boolean;
  userRoleInApp: TUserSlice['userRoleInApp'];
  rightPanel?: boolean;
  userIdProp?: number;
  ownerId: TUserProfile['id'];
};

const mapStateToProps = (state: RootState) => ({
  isMobile: state.app.isMobile,
  userRole: state.user.userProfile.role,
  updateExternalAccounts: state.user.updateExternalAccounts,
  userRoleInApp: state.user.userRoleInApp,
  ownerId: state.user.userProfile.id,
});
const ApplicationUserInfoComponent: FC<TApplicationUserInfoComponent> = ({
  userRole,
  isMobile,
  updateExternalAccounts,
  userRoleInApp,
  userIdProp,
  rightPanel,
  ownerId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { prevPath } = (history.location.state as TUseHistoryProps) || { prevPath: '/users' };
  const { clientId, userId } = rightPanel
    ? { clientId: CLIENT_ID, userId: userIdProp }
    : useParams<{ clientId: string; userId: string }>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: string } | null | undefined
  >(null);
  const [toggleUpdateSelectedUser, setToggleUpdateSelectedUser] = useState(false);
  const date = new Date(selectedUser?.user.birthdate || 0);
  const [getUsers] = useLazyGetUsersQuery();
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const { data: externalAccounts, refetch } = useGetExternalAccountsQuery(String(userId), {
    skip: !isOwner(userRole) || !userId,
  });
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userId), {
    skip: !isOwner(userRole) || !userId,
  });
  const { public_profile_claims } = privateClaims || {};
  const isAdmin = (role?: string) => isAdministrator(role) || isEditor(role);

  useEffect(() => {
    const start = async () => {
      if (!userId) return;
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: String(userId),
      });

      setSelectedUser(users?.find((user) => user.user.id === Number(userId)));
    };
    start();
  }, [clientId, userId, toggleUpdateSelectedUser]);

  useEffect(() => {
    if (updateExternalAccounts) {
      dispatch(setUpdateExternalAccounts(false));
      refetch();
    }
  }, []);

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => setAnchorEl(null);

  return (
    <div className={clsx(styles.wrapper, { [styles['panel-wrapper']]: rightPanel })}>
      <div className={clsx(styles.content, { [styles['panel-content']]: rightPanel })}>
        {!rightPanel && (
          <Button
            onClick={() => history.push(prevPath)}
            className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
            startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
          >
            {prevPath.startsWith('/applications')
              ? 'Приложения'
              : prevPath.startsWith('/application')
              ? 'Приложение'
              : ' Пользователи'}
          </Button>
        )}
        <div className={styles['panel-top']}>
          <div
            className={styles['app-icon-wrapper']}
            style={{
              backgroundImage: `url(${getImageURL(selectedUser?.user?.picture)})`,
            }}
          >
            {!selectedUser?.user.picture && (
              <div className={styles['app-icon-default']}>
                {selectedUser?.user.nickname
                  ?.split(' ')
                  .map((name: string) => name[0]?.toUpperCase())
                  .join('')}
              </div>
            )}
          </div>
          <div className={styles['name-wrapper']}>
            <Typography
              className={clsx(
                'header-2-medium',
                'font-golos',
                'color-0B1641',
                styles['overflow-ellipsis'],
              )}
              component="div"
            >
              {(selectedUser?.user.nickname || '').trim()
                ? selectedUser?.user.nickname
                : (
                    (selectedUser?.user.given_name || '') +
                    ' ' +
                    (selectedUser?.user.family_name || '')
                  ).trim() || 'Нет имени'}
            </Typography>
            <Popover
              classes={{
                paper: styles.paper,
              }}
              PaperProps={{
                sx: {
                  transform: `translateX(${
                    290 - (anchorEl?.offsetWidth || 290)
                  }px) translateY(12px) !important`,
                },
              }}
              onClose={handleClosePopover}
              anchorEl={anchorEl}
              open={isPopoverOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <CustomPopoverButton
                startIcon={<QuitIcon className={styles['action-button-icon']} />}
                onClick={async () => {
                  await deleteAllSession(selectedUser?.user.id);
                  window.location.reload();
                }}
              >
                Завершить сеансы
              </CustomPopoverButton>
            </Popover>
            {!isMobile && (
              <div className={styles['user-buttons']}>
                <Button
                  onClick={isOwnerOrEditor(userRole) ? handleOpenPopover : () => ''}
                  className={clsx('color-0B1641', 'text-14', styles['popover-button'])}
                  variant="custom"
                  color="secondary"
                  endIcon={<ArrowDown fill="#0B1641" />}
                >
                  {isAdmin(selectedUser?.role) ? 'Администратор' : 'Участник'}
                </Button>
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <div className={styles['mobile-buttons']}>
            <Button
              onClick={isOwnerOrEditor(userRole) ? handleOpenPopover : () => ''}
              className={clsx('color-0B1641', 'text-14', styles['popover-button'])}
              variant="custom"
              color="secondary"
              endIcon={<ArrowDown fill="#0B1641" />}
            >
              {isOwnerOrEditor(selectedUser?.role) ? 'Администратор' : 'Участник'}
            </Button>
          </div>
        )}
        <div className={styles.panel}>
          <div className={styles['panel-title']}>
            <Typography className={clsx('header-3', 'font-golos')}>Основная информация</Typography>
            {isOwner(userRole) && (
              <Link
                to={`/application/user/edit/${clientId}/${userId}`}
                style={{ marginLeft: 'auto', textDecoration: 'none' }}
                className={styles['margin-right']}
              >
                <Button variant="custom2">Изменить</Button>
              </Link>
            )}
          </div>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  ID пользователя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {selectedUser?.user.id}
                </Typography>
              </div>
              {isOwner(userRole) && (
                <PublicStatusPopover
                  isPublic={!!public_profile_claims?.includes('id')}
                  claims="id"
                  disabled
                />
              )}
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичное имя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {(selectedUser?.user.nickname || '').trim()
                    ? selectedUser?.user.nickname
                    : 'Нет имени'}
                </Typography>
              </div>
              {isOwner(userRole) && (
                <PublicStatusPopover
                  isPublic={!!public_profile_claims?.includes('nickname')}
                  claims="nickname"
                  userId={String(selectedUser?.user.id)}
                />
              )}
            </div>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Фото профиля
              </Typography>
              {selectedUser?.user.picture ? (
                <div
                  style={{
                    backgroundImage: `url(${getImageURL(selectedUser?.user?.picture)})`,
                  }}
                  className={styles['user-icon-wrapper']}
                />
              ) : (
                <Avatar className={styles.avatar}>
                  <AvatarIcon />
                </Avatar>
              )}
              {isOwner(userRole) && (
                <PublicStatusPopover
                  isPublic={!!public_profile_claims?.includes('picture')}
                  claims="picture"
                  userId={String(selectedUser?.user.id)}
                />
              )}
            </div>
            {isOwner(userRole) && (
              <>
                <div className={styles['info-item']}>
                  <div className={styles['flex-wrap']}>
                    <Typography
                      className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                    >
                      Имя и фамилия
                    </Typography>
                    <Typography
                      className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                    >
                      {(
                        (selectedUser?.user.given_name || '') +
                        ' ' +
                        (selectedUser?.user.family_name || '')
                      ).trim() || 'Не задано'}
                    </Typography>
                  </div>
                  <PublicStatusPopover
                    isPublic={
                      !!public_profile_claims?.includes('family_name') ||
                      !!public_profile_claims?.includes('given_name')
                    }
                    claims="family_name given_name"
                    userId={String(selectedUser?.user.id)}
                  />
                </div>
                <div className={styles['info-item']}>
                  <div className={styles['flex-wrap']}>
                    <Typography
                      className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                    >
                      Логин
                    </Typography>
                    <Typography
                      className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                    >
                      {selectedUser?.user.login || 'Не задано'}
                    </Typography>
                  </div>
                  <PublicStatusPopover
                    isPublic={!!public_profile_claims?.includes('login')}
                    claims="login"
                    userId={String(selectedUser?.user.id)}
                  />
                </div>
                <div className={styles['info-item']}>
                  <div className={styles['flex-wrap']}>
                    <Typography
                      className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                    >
                      Дата рождения
                    </Typography>
                    <Typography
                      className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                    >
                      {selectedUser?.user.birthdate
                        ? `0${date.getDate()}`.slice(-2) +
                          ` ${getMonthByNumber(date.getMonth())} ${date.getFullYear()} г.`
                        : 'Не задано'}
                    </Typography>
                  </div>
                  <PublicStatusPopover
                    isPublic={!!public_profile_claims?.includes('birthdate')}
                    claims="birthdate"
                    userId={String(selectedUser?.user.id)}
                  />
                </div>
              </>
            )}
            {/* #427
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичность
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  Профиль не участвует в поиске
                </Typography>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.justify}>
            <Typography
              style={{ marginBottom: 24 }}
              className={clsx('text-17-regular', 'font-golos')}
            >
              Способы входа
            </Typography>
            {isOwner(userRole) && (
              <Button
                variant="custom2"
                className={styles['add-button']}
                onClick={() =>
                  history.push(`/application/user/external-provider/${clientId}/${userId}`)
                }
              >
                Добавить
              </Button>
            )}
          </div>
          <div className={styles.info}>
            {isOwner(userRole) &&
              selectedUser?.user &&
              externalAccounts?.map((account) => (
                <ExternalAccount
                  selectedUser={selectedUser.user}
                  key={account.sub || account.email}
                  account={account}
                  clientId={clientId}
                  setToggleUpdateSelectedUser={setToggleUpdateSelectedUser}
                />
              ))}
            {userRoleInApp === Roles.EDITOR && selectedUser?.user && (
              <div className={styles['info-item']} style={{ justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <div className={styles['provider-icon']}>
                    <MailIcon />
                  </div>
                  <div style={{ marginLeft: 24 }}>
                    <Typography className={clsx('text-14', 'color-B1641')}>
                      {selectedUser?.user.email}
                    </Typography>
                    <Typography className={clsx('text-12', 'color-858BA0')}>EMAIL</Typography>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Accordion className={clsx(styles.panel, styles.accordion)}>
          <AccordionSummary
            className={styles['accorion-summary']}
            classes={{ content: styles['accorion-summary-content'] }}
            expandIcon={<ArrowDownIcon fill="#0B1641" />}
          >
            <Typography className={clsx('text-17-regular', 'font-golos')}>
              Другие действия
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <Button
              variant="custom2"
              className={styles['margin-bottom']}
              onClick={async () => {
                await deleteAllSession(selectedUser?.user.id);
              }}
            >
              Выйти со всех устройств
            </Button>
            {isOwner(userRole) && (
              <>
                <Button
                  onClick={() => exportToJson({ ...selectedUser }, 'profile.json')}
                  variant="custom2"
                  className={styles['margin-bottom']}
                >
                  Скачать данные
                </Button>
                {selectedUser?.user.id !== 1 &&
                  selectedUser?.user.id !== parseInt(ownerId as string, 10) && (
                    <Button
                      onClick={() => history.push(`/application/user/delete/${clientId}/${userId}`)}
                      variant="custom2"
                      className={styles['margin-bottom']}
                    >
                      Удалить аккаунт
                    </Button>
                  )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

type ExternalAccountProps = {
  account: TExternalAccount;
  selectedUser: Partial<TUser>;
  clientId: string;
  setToggleUpdateSelectedUser: Dispatch<SetStateAction<boolean>>;
};

const ExternalAccount: FC<ExternalAccountProps> = ({
  account: { user_id, sub, given_name, family_name, type, avatar, email, id },
  selectedUser: { email: mainEmail, id: userId },
  clientId,
  setToggleUpdateSelectedUser,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorId, setAnchorId] = useState<string | null>(null);
  const [deleteExternalAccount] = useDeleteExternalAccountMutation();
  const [setMainEmail] = useSetMainEmailMutation();
  const history = useHistory();
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userId), {
    skip: !userId,
  });
  const { public_external_accounts_ids } = privateClaims || {};

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>, id?: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (id) setAnchorId(id);
  };

  const getAvatar = () => {
    if (avatar)
      return <img className={styles['provider-icon']} src={avatar} width="40" height="40" />;

    switch (type) {
      case AccountTypes.EMAIL:
        return <MailIcon />;
      case AccountTypes.KLOUD:
        return <AccountTypesBlock pathToImage="/public/images/provider/kloud.svg" />;
      case AccountTypes.QRCODE:
        return <AccountTypesBlock pathToImage="/public/images/provider/qrcode.svg" />;
      default:
        return <IdIcon />;
    }
  };

  const getLabel = () => {
    switch (type) {
      case AccountTypes.EMAIL:
        return email;
      case AccountTypes.QRCODE:
      case AccountTypes.KLOUD:
      case AccountTypes.ETHEREUM:
        return sub;
      default:
        return (given_name || '') + ' ' + (family_name || '');
    }
  };

  const handleClosePopover = (event: MouseEvent<ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setAnchorId(null);
  };

  const handleDeleteClick = async () => {
    await deleteExternalAccount({ userId: user_id, accountId: id });
    setAnchorEl(null);
    setAnchorId(null);
  };

  const handleSetMainEmail = async (email: string) => {
    await setMainEmail({ email, userId: String(userId) });
    setAnchorEl(null);
    setAnchorId(null);
    setToggleUpdateSelectedUser((toggleUpdateSelectedUser) => !toggleUpdateSelectedUser);
  };

  return (
    <div className={styles['info-item']} style={{ justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', width: 'calc(100% - 119px)' }}>
        <div className={styles['provider-icon']}>{getAvatar()}</div>
        <div className={styles['provider-info']}>
          <Typography
            className={clsx('text-14', 'color-B1641', styles['overflow-ellipsis'], styles.ellipsis)}
          >
            {getLabel()}
          </Typography>
          <Typography className={clsx('text-12', 'color-858BA0')}>{type}</Typography>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <IconButton
          onClick={(event) => handleOpenPopover(event, sub || email)}
          className={clsx(styles['actions-icon'], {
            [styles['active-actions-icon']]: anchorId && (anchorId === sub || anchorId === email),
          })}
        >
          <ActionsIcon />
        </IconButton>
        <PublicStatusPopover
          userId={String(user_id)}
          isPublic={!!public_external_accounts_ids?.includes(parseInt(id, 10))}
          publicExternalAccounts={[parseInt(id, 10)]}
        />
      </div>
      <Popover
        classes={{
          paper: clsx(styles['provider-paper']),
        }}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Button
          className={clsx(styles['provider-popover-button'])}
          onClick={() => {
            type === AccountTypes.EMAIL && email === mainEmail
              ? history.push(`/application/user/email/change/${clientId}/${userId}`)
              : handleDeleteClick();
          }}
        >
          <Typography style={{ marginLeft: 16 }} className={clsx('text-14', 'color-0B1641')}>
            {type === AccountTypes.EMAIL && email === mainEmail ? 'Изменить' : 'Удалить'}
          </Typography>
        </Button>
        {type === AccountTypes.EMAIL && email !== mainEmail && (
          <Button
            className={clsx(styles['provider-popover-button'])}
            onClick={() => {
              if (email) handleSetMainEmail(email);
            }}
          >
            <Typography style={{ marginLeft: 16 }} className={clsx('text-14', 'color-0B1641')}>
              Сделать основной
            </Typography>
          </Button>
        )}
      </Popover>
    </div>
  );
};

type AccountTypesProps = {
  pathToImage: string;
};

const AccountTypesBlock: FC<AccountTypesProps> = ({ pathToImage }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${BACKEND_URL + pathToImage})`,
      }}
      className={styles['provider-icon-wrapper']}
    />
  );
};

export const ApplicationUserInfo = connect(mapStateToProps)(ApplicationUserInfoComponent);
