import Button from '@mui/material/Button';
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useEffect,
  useState,
  // #371 KeyboardEvent,
  useRef,
} from 'react';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import clsx from 'clsx';
import styles from './CreateApplication.module.css';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { UploadAndDisplayImage } from '../UploadAndDisplayImage';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateApplicationMutation } from '../../redux/services/client';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { DescriptionField, RedirectUrisField } from './EditApplicationFields';
// #371 import Switch from '@mui/material/Switch';
import { setApplicationFormChanged } from '../../redux/appSlice';
import Modal from '@mui/material/Modal';
import { /* #371 getDeclinationByNumber,*/ isObjectEmpty, isUrl } from '../../helpers';
import { IconButton } from '@mui/material';
// #371 import { ACCESS_TOKEN_TTL } from '../../constants';

type Inputs = {
  name: string;
  description: string;
  domain: string;
  redirectUri: {
    name: string;
    value: string;
  }[];
  logoutUri: {
    name: string;
    value: string;
  }[];
  // #371 refresh_token_ttl: number;
  // #371 access_token_ttl: number;
  avatar: File | null;
};

const redirectUriSchema = yup.object({
  value: yup
    .string()
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value)
    }),
  // #520
  // .url('Неверный формат ссылки')
  // .matches(/^(http|https)/, {
  //   excludeEmptyString: true,
  //   message: 'Адрес должен начинаться с http:// или https://',
  // }),
});

const logoutUriSchema = yup.object({
  value: yup
    .string()
    // #520 .url('Неверный формат ссылки')
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value);
    })
});

const schema = yup
  .object({
    name: yup
      .string()
      .max(50, 'Название не может превышать 50 символов')
      .required('Обязательное поле'),
    description: yup.string().max(255).min(0),
    domain: yup
      .string()
      // #520 .url('Неверный формат ссылки')
      .max(2000, 'Ссылка не может превышать 2000 символов')
      .test('is-url', 'Неверный формат ссылки', (value?: string) => {
        if (!value) return true;
        return isUrl(value);
      })
      .required('Обязательное поле'),
    redirectUri: yup.array().of(redirectUriSchema).required(),
    logoutUri: yup.array().of(logoutUriSchema).required(),
    // #371
    // refresh_token_ttl: yup
    //   .number()
    //   .min(1800, 'Время жизни токена токена обновления не может быть меньше 1800 секунд')
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    // access_token_ttl: yup
    //   .number()
    //   .min(60, 'Время жизни токена доступа не может быть меньше 60 секунд')
    //   .max(
    //     ACCESS_TOKEN_TTL,
    //     `Время жизни токена доступа не может превышать ${ACCESS_TOKEN_TTL} секунд` +
    //       (ACCESS_TOKEN_TTL / 60 / 60 / 24 >= 1
    //         ? ` (${
    //             ACCESS_TOKEN_TTL / 60 / 60 / 24 +
    //             ' ' +
    //             getDeclinationByNumber(ACCESS_TOKEN_TTL / 60 / 60 / 24, ['день', 'дня', 'дней'])
    //           }).`
    //         : ''),
    //   )
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
  })
  .required();

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
});

const CreateApplicationComponent: FC<{ userId?: string }> = ({ userId }) => {
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  // #371 const [showRefreshTokenInput, setShowRefreshTokenInput] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const savedCallback = useRef<() => void>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );
  const history = useHistory();
  const [createApplication, result] = useCreateApplicationMutation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    trigger,
    getValues,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      avatar: null,
      description: '',
      domain: '',
      redirectUri: [{ value: '' }],
      logoutUri: [{ value: '' }],
      // #371 refresh_token_ttl: 86400,
      // #371 access_token_ttl: 1800,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (result.isSuccess) history.push('/applications');
  }, [result]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field)) return field.some((elem) => elem.value);
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  const closeSaveModal = () => setSaveModalOpen(false);

  const {
    fields: redirectUris,
    append: redirectAppend,
    remove: redirectRemove,
  } = useFieldArray({
    control,
    name: 'redirectUri',
  });

  const {
    fields: logoutUris,
    append: logoutAppend,
    remove: logoutRemove,
  } = useFieldArray({
    control,
    name: 'logoutUri',
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    let duplicateRedirectUri;

    if (data.redirectUri.every((uri) => !uri.value)) {
      setError(`redirectUri.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;

    data.redirectUri.reduce((acc: string[], item, index) => {
      if (acc.includes(item.value)) {
        setError(`redirectUri.${index}.value`, {
          message: 'Поле с таким адресом уже существует',
        });
        duplicateRedirectUri = true;
      }
      acc.push(item.value);
      return acc;
    }, [] as string[]);

    if (duplicateRedirectUri) return;

    createApplication({
      name: data.name,
      redirect_uris: data.redirectUri.map((uri) => uri.value).filter((uri) => !!uri),
      user_id: userId,
      post_logout_redirect_uris: data.logoutUri.map((uri) => uri.value).filter((uri) => !!uri),
      grant_types: ['authorization_code', 'refresh_token'],
      avatar: data.avatar,
      domain: data.domain,
      description: data.description,
    });
  };
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => {
          if (applicationFormChanged) {
            savedCallback.current = () => history.push('/applications');
            return setSaveModalOpen(true);
          }
          history.push('/applications');
        }}
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Приложения
      </Button>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
        <div className={styles['padding-wrapper']}>
          <Typography
            className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
          >
            Создать приложение
          </Typography>
          <Typography
            className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
          >
            Основная информация
          </Typography>
          <Typography
            className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
          >
            Название приложения
          </Typography>
          <TextField
            {...register('name', {
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                setValue('name', event.target.value.trim());
              },
              onChange: () => {
                if (errors.name) clearErrors('name');
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Имя приложения, отображаемое пользователям
          </Typography>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Описание приложения
          </Typography>
          <TextField
            {...register('description', {
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length > 255) {
                  setError('description', {
                    message: 'Невозможно ввести более 255 символов',
                    type: 'validate',
                  });
                  setValue('description', event.target.value.slice(0, 255));
                } else {
                  clearErrors('description');
                }
              },
            })}
            className="custom"
            fullWidth
            variant="standard"
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ''}
            multiline
          />
          <DescriptionField control={control} />
          <div>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Логотип приложения
            </Typography>
            <UploadAndDisplayImage
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
              componentName="create-client"
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              defaultValue={null}
              setAvatarValue={setAvatarValue}
              DefaultIcon={<AppIcon fill="#ced0d9" />}
            />
            {errors.avatar && (
              <Typography className={clsx('text-14', styles['input-error'])}>
                {errors.avatar.message}
              </Typography>
            )}
            <Typography className={clsx('text-14', 'color-858BA0')}>
              Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
            </Typography>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles['padding-wrapper']}>
          <Typography
            className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
          >
            Параметры приложения
          </Typography>
          <Typography
            className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
          >
            Адрес приложения
          </Typography>
          <TextField
            {...register('domain', {
              onChange: () => {
                if (errors.domain) clearErrors('domain');
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.domain}
            helperText={errors.domain ? errors.domain.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Адрес приложения в формате «протокол://доменное имя:порт»
          </Typography>
          <>
            {redirectUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <Typography
                    className={clsx(
                      'text-14',
                      'color-0B1641',
                      styles.asterisk,
                      styles['input-title'],
                    )}
                  >
                    Возвратный URL #{index + 1} (Redirect_uri)
                  </Typography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`redirectUri.${index}.value`, {
                        onChange: () => {
                          if (errors?.redirectUri?.[index])
                            clearErrors(`redirectUri.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      FormHelperTextProps={{
                        className: clsx('text-14', 'color-858BA0'),
                      }}
                      onBlur={() => {
                        if (getValues('redirectUri').every((uri) => !uri.value))
                          setError(`redirectUri.0.value`, { message: 'Обязательное поле' });
                        else {
                          if (errors?.redirectUri?.[0]) clearErrors(`redirectUri.0.value`);
                          trigger(`redirectUri.${index}.value`);
                        }
                      }}
                      error={!!errors.redirectUri?.[index]}
                      helperText={
                        errors.redirectUri ? errors?.redirectUri?.[index]?.value?.message : ''
                      }
                      variant="standard"
                    />
                    {redirectUris.length > 1 ? (
                      <Button
                        variant="custom"
                        color="secondary"
                        onClick={() => redirectRemove(index)}
                        className={clsx(styles['delete-button'])}
                      >
                        Удалить
                      </Button>
                    ) : (
                      <RedirectUrisField
                        name="redirectUri"
                        control={control}
                        onClick={() => redirectAppend({ value: '', name: '' })}
                        className={styles['add-button']}
                      />
                    )}
                  </div>
                  {index === 0 && (
                    <Typography
                      className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}
                    >
                      Адрес, на который пользователь переадресовывается после авторизации
                    </Typography>
                  )}
                </div>
              );
            })}
            {redirectUris.length > 1 && (
              <RedirectUrisField
                name="redirectUri"
                control={control}
                onClick={() => redirectAppend({ value: '', name: '' })}
                className={clsx(styles['add-button'], styles['add-button-bottom'])}
              />
            )}
          </>
          <>
            {logoutUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    URL выхода #{index + 1} (Post_logout_redirect_uri)
                  </Typography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`logoutUri.${index}.value`, {
                        required: true,
                        onChange: () => {
                          if (errors?.logoutUri?.[index]) clearErrors(`logoutUri.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      FormHelperTextProps={{
                        className: clsx('text-14', 'color-858BA0', styles['helper-text']),
                      }}
                      error={!!errors.logoutUri?.[index]}
                      helperText={
                        errors.logoutUri ? errors?.logoutUri?.[index]?.value?.message : ''
                      }
                      variant="standard"
                    />
                    {logoutUris.length > 1 ? (
                      <Button
                        variant="custom"
                        color="secondary"
                        onClick={() => logoutRemove(index)}
                        className={clsx(styles['delete-button'])}
                      >
                        Удалить
                      </Button>
                    ) : (
                      <RedirectUrisField
                        name="logoutUri"
                        control={control}
                        onClick={() => logoutAppend({ value: '', name: '' })}
                        className={styles['add-button']}
                      />
                    )}
                  </div>
                  {index === 0 && (
                    <Typography
                      className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}
                      style={{ width: '85%' }}
                    >
                      Адрес, на который переадресовывается пользователь после выхода. Если значение
                      не указано, то используется «Возвратный URL»
                    </Typography>
                  )}
                </div>
              );
            })}
            {logoutUris.length > 1 && (
              <RedirectUrisField
                name="logoutUri"
                control={control}
                onClick={() => logoutAppend({ value: '', name: '' })}
              />
            )}
          </>
          {/* #371
          <div className={styles['switch-wrapper']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>
              Ограничить время действия токена обновления (refresh_token)
            </Typography>
            <Switch
              value={showRefreshTokenInput}
              onChange={() => setShowRefreshTokenInput((shown) => !shown)}
              disableRipple
            />
          </div>

          {showRefreshTokenInput && (
            <>
              <Typography
                className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
              >
                Время действия токена обновления (refresh_token)
              </Typography>
              <div style={{ position: 'relative' }}>
                <TextField
                  {...register('refresh_token_ttl', {
                    onChange: () => {
                      if (errors.refresh_token_ttl) clearErrors('refresh_token_ttl');
                    },
                  })}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key.toLowerCase() === 'e') e.preventDefault();
                  }}
                  type="number"
                  className={clsx(styles['token-textfield'], 'custom')}
                  InputProps={{
                    className: styles['token-input'],
                  }}
                  FormHelperTextProps={{
                    className: clsx('text-14', 'color-858BA0'),
                  }}
                  error={!!errors.refresh_token_ttl}
                  helperText={errors.refresh_token_ttl ? errors.refresh_token_ttl.message : ''}
                  fullWidth
                  variant="standard"
                />
                <Typography
                  className={clsx('text-14', 'color-858BA0')}
                  style={{ position: 'absolute', right: 40, top: 10 }}
                >
                  секунд
                </Typography>
              </div>
            </>
          )}
          <Typography
            className={clsx(
              'text-14',
              'color-0B1641',
              styles.asterisk,
              styles['input-title'],
              styles['input-subtitle'],
            )}
          >
            Время действия авторизационного токена (access_token)
          </Typography>
          <div style={{ position: 'relative' }}>
            <TextField
              {...register('access_token_ttl', {
                onChange: () => {
                  if (errors.access_token_ttl) clearErrors('access_token_ttl');
                },
              })}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key.toLowerCase() === 'e') e.preventDefault();
              }}
              type="number"
              className={clsx('custom', styles['token-textfield'])}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              fullWidth
              variant="standard"
            />
            <Typography
              className={clsx('text-14', 'color-858BA0')}
              style={{ position: 'absolute', right: 40, top: 10 }}
            >
              секунд
            </Typography>
          </div>
          {!!errors.access_token_ttl && (
            <Typography className={clsx('text-14', styles['input-error'])}>
              {errors.access_token_ttl.message}
            </Typography>
          )} */}
          <div className={styles['submit-buttons']}>
            <Button
              onClick={() => {
                if (applicationFormChanged) {
                  savedCallback.current = () => history.goBack();
                  return setSaveModalOpen(true);
                }
                history.goBack();
              }}
              variant="custom"
              color="secondary"
            >
              Отмена
            </Button>
            <Button className={styles['create-button']} type="submit" variant="custom">
              Создать
            </Button>
          </div>
        </div>
      </form>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                savedCallback.current?.();
                dispatch(setApplicationFormChanged(false));
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const CreateApplication = connect(mapStateToProps)(CreateApplicationComponent);
