import { ReactElement } from 'react';
import { Order } from './components/applications/ApplicationsTable';
import { Roles } from './enums';
import { MiscProviderType, OauthProviderType } from './redux/services/provider';
import { BACKEND_URL } from './constants';
import { TUserProfile } from './redux/userSlice';
import { TSettings } from './redux/services/client';

export const getObjectKeys = <T extends Record<string, unknown>>(object: T): Array<keyof T> =>
  <Array<keyof T>>Object.keys(object);

export const isObjectEmpty = (object: Record<string, unknown>): boolean =>
  !Object.keys(object).length;

export const getObjectEntries = <T extends Record<string, never>, K extends keyof T>(
  object: T,
): [keyof T, T[K]][] => Object.entries(object);

export const convertUserProfile = (
  data: Omit<TUserProfile, 'id'> & { sub: string },
): TUserProfile => {
  const result = getObjectKeys(data).reduce(
    (acc: TUserProfile, key) => {
      if (key === 'sub') return acc;
      if (key === 'role') {
        acc.role = data.role;
        return acc;
      }

      (acc[key] as string | boolean | number[]) = data[key] || '';

      return acc;
    },
    { id: data.sub },
  );

  return result;
};

export const isOwnerOrEditor = (role?: string): boolean =>
  role === Roles.OWNER || role === Roles.EDITOR;

export const isOwner = (role?: string): boolean => role === Roles.OWNER;

export const isAdministrator = (role?: string): boolean =>
  role === Roles.OWNER || role === Roles.ADMIN;

export const isEditor = (role?: string): boolean => role === Roles.EDITOR;

export const getRoleName = (role: string): string => {
  switch (role) {
    case Roles.OWNER:
      return 'Владелец';
    case Roles.ADMIN:
      return 'Администратор личного кабинета';
    case Roles.EDITOR:
      return 'Администратор приложения';
    case Roles.USER:
      return 'Участник';
    default:
      return '';
  }
};

export const getMonthByNumber = (month: number): string => {
  switch (month) {
    case 0:
      return 'января';
    case 1:
      return 'февраля';
    case 2:
      return 'марта';
    case 3:
      return 'апреля';
    case 4:
      return 'мая';
    case 5:
      return 'июня';
    case 6:
      return 'июля';
    case 7:
      return 'августа';
    case 8:
      return 'сентября';
    case 9:
      return 'октября';
    case 10:
      return 'ноября';
    case 11:
      return 'декабря';
    default:
      return '';
  }
};

export const getImageURL = (path?: string | null) => {
  if (!path) return undefined;
  return path?.startsWith('http://') || path?.startsWith('https://')
    ? path
    : `${BACKEND_URL}/${path}`;
};

export const sortList = <T, K extends keyof T>(data: T[], orderBy: K, order: Order): T[] => {
  const arr = [...data].sort((a, b) => {
    const aItem = a[orderBy] || '';
    const bItem = b[orderBy] || '';
    if (order === 'asc') {
      return aItem > bItem ? 1 : -1;
    }
    return aItem > bItem ? -1 : 1;
  });
  return arr;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function throttle(func: Function, interval: number): (...args: unknown[]) => void {
  let timeout = false;
  return function (...args: unknown[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const later = function () {
      timeout = false;
    };
    if (!timeout) {
      func.apply(context, args);
      timeout = true;
      setTimeout(later, interval);
    }
  };
}

export const createFullDateString = (date: Date) => {
  return (
    `0${date.getDate()}`.slice(-2) +
    ` ${getMonthByNumber(date.getMonth())} ${date.getFullYear()} г., ${`0${date.getHours()}`.slice(
      -2,
    )}:${`0${date.getMinutes()}`.slice(-2)}`
  );
};

export const exportToJson = (objectData: Record<string, unknown>, objectName: string) => {
  const filename = objectName;
  const contentType = 'application/json;charset=utf-8;';
  const a = document.createElement('a');
  a.download = filename;
  a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const randomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getOffset = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? '+' : '-';
  const offsetHours = (offset / 60).toString().padStart(2, '0');
  const offsetMinutes = (offset % 60).toString().padStart(2, '0');
  return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
};

export const replaceJSX = (str: string, find: string, replace: ReactElement) => {
  const arr = str.split(find);
  return arr.flatMap((item, index) => {
    if (index !== arr.length - 1) return [item, replace];
    return item;
  });
};

export const getProviderTitleByType = (type: OauthProviderType | MiscProviderType) => {
  switch (type) {
    case MiscProviderType.EMAIL:
    case MiscProviderType.CREDENTIALS:
      return '';
    case MiscProviderType.ETHEREUM:
      return 'ETHEREUM';
    case MiscProviderType.SMS:
      return 'SMS';
    case MiscProviderType.LDAP:
      return 'LDAP';
    case MiscProviderType.ALDPRO:
      return 'ALDPRO';
    case MiscProviderType._1C:
      return '1C';
    default:
      return 'Oauth2';
  }
};

export const checkForNumberEnding = (number: number) => {
  return String(number).endsWith('1') && !String(number).endsWith('11');
};

export const formatSpecChar = (specs: string) => {
  if (specs) {
    return specs
      .split('')
      .reduce((acc: string[], item) => {
        if (item === '[' || item === ']' || item === '/' || item === '-') acc.push(`\\${item}+`);
        else acc.push(item);
        return acc;
      }, [])
      .join('');
  } else {
    return '-~!@_#$"№%:^&?*()|{}+=\\]+\\[+\\/+\\+';
  }
};

const basicCharacterSet = (settings?: TSettings) =>
  `${
    formatSpecChar(settings?.allowed_special_symbols || '') +
    (settings?.allowed_symbols || 'a-zа-яё')
  }${String(settings?.allowed_symbols || 'a-zа-яё').toUpperCase()}0-9`;

export const validatePassword = (password: string, settings?: TSettings) => {
  if (!settings) return '';
  if (!new RegExp(`^[${basicCharacterSet(settings)}]+$`).exec(password)) {
    if (password.includes(' ')) return 'Пароль не должен содержать пробелы';
    return `Пароль может содержать ${settings.allowed_symbols || 'буквы'}, цифры, спецсимволы ${
      settings.allowed_special_symbols ? formatSpecChar(settings.allowed_special_symbols) : ''
    }`;
  }
  if (password.length < settings.length_char_min)
    return `Используйте не менее ${settings.length_char_min} ${
      checkForNumberEnding(settings.length_char_min) ? 'символа' : 'символов'
    }`;
  if (password.length > settings.length_char_max)
    return `Используйте не более ${settings.length_char_max} ${
      checkForNumberEnding(settings.length_char_max) ? 'символа' : 'символов'
    }`;
  if (
    !new RegExp(
      `^(?=(.*[${formatSpecChar(settings.allowed_special_symbols)}]){${
        settings.spec_char || 0
      }})(.+)[${basicCharacterSet(settings)}]*$`,
    ).exec(password)
  ) {
    const errorForRegular = `Должен содержать не менее ${settings.spec_char}`;
    if (settings.allowed_special_symbols) {
      return `${errorForRegular} из следующих спецсимволов ${formatSpecChar(
        settings.allowed_special_symbols,
      )}`;
    } else {
      return `${errorForRegular} ${
        checkForNumberEnding(settings.spec_char) ? 'спецсимвола' : 'спецсимволов'
      }`;
    }
  }
  if (
    !new RegExp(
      `^(?=(.*[0-9]){${settings.number || 0}})(.+)[${basicCharacterSet(settings)}]*$`,
    ).exec(password)
  )
    return `Пароль должен содержать не менее ${settings.number || 0} ${
      checkForNumberEnding(settings.number) ? 'цифры' : 'цифр'
    }`;
  if (
    !new RegExp(
      `^(?=(.*[${String(settings.allowed_symbols || 'a-zа-яё').toUpperCase()}]){${
        settings.min_uppercase_count || 0
      }})(.+)[${basicCharacterSet(settings)}]*$`,
    ).exec(password)
  ) {
    return `Пароль должен содержать не менее ${settings.min_uppercase_count || 0} ${
      checkForNumberEnding(settings.min_uppercase_count) ? 'заглавной буквы' : 'заглавных букв'
    }`;
  }
  return null;
};

export const getDeclinationByNumber = (number: number, words: string[]) => {
  if (number !== Math.floor(number)) return words[1];
  if (number % 10 === 0 || number % 10 > 4 || (number % 100 > 10 && number % 100 < 20))
    return words[2];
  if (number % 10 === 1) return words[0];
  return words[1];
};

export const generateYearsBetween = (startYear: number, endYear: number) => {
  const years = [];
  for (let i = endYear; i >= startYear; i--) {
    years.push(endYear);
    endYear--;
  }
  return years;
};

export const isUrl = (value: string | undefined) => {
  if (!value) return false;

  const pattern =
    /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

  return pattern.test(value);
};
