import { ReactComponent as CopyIcon } from '../../icons/Copy.svg';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  // #371 KeyboardEvent,
} from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import {
  MiscProviderType,
  TMiscProvider,
  TOauthProvider,
  useDeactivateProvidersMutation,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import Skeleton from '@mui/material/Skeleton';
import { DescriptionField, RedirectUrisField } from './EditApplicationFields';
import { UploadAndDisplayImage } from '../UploadAndDisplayImage';
import { /* #371 ACCESS_TOKEN_TTL,*/ BACKEND_URL, CLIENT_ID, LOGO_URL } from '../../constants';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TEditAppicationInputs } from './EditApplication';
import { TClient } from '../../redux/services/client';
import { IconWrapper } from '../IconWrapper';
import { getProviderTitleByType } from '../../helpers';

type TEditApplicationHeader = {
  setSelectedProvider: Dispatch<SetStateAction<TOauthProvider | TMiscProvider | undefined>>;
  setProviderModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedClient: TClient;
  avatarSrc: string | null;
  coverSrc: string | null;
  setAvatarSrc: Dispatch<SetStateAction<string | null>>;
  openedFromMenu?: string;
  setCoverSrc: Dispatch<SetStateAction<string | null>>;
};

export const EditApplicationHeader: FC<TEditApplicationHeader> = ({
  setSelectedProvider,
  setProviderModalOpen,
  selectedClient,
  avatarSrc,
  coverSrc,
  setAvatarSrc,
  openedFromMenu,
  setCoverSrc,
}) => {
  const {
    register,
    control,
    clearErrors,
    setValue,
    setError,
    getValues,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext<TEditAppicationInputs>();

  const { data: providers, isLoading: providersLoading } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    onlyActive: false,
  });

  const [deactivateProvider] = useDeactivateProvidersMutation();

  const {
    fields: redirectUris,
    append: redirectAppend,
    remove: redirectRemove,
  } = useFieldArray({
    control,
    name: 'redirect_uris',
  });
  const {
    fields: logoutUris,
    append: logoutAppend,
    remove: logoutRemove,
  } = useFieldArray({
    control,
    name: 'post_logout_redirect_uris',
  });
  const watchClientSecret = watch('client_secret');
  const isAdminClient = selectedClient.client_id === CLIENT_ID;

  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setCoverValue = (value: File | null) => setValue('cover', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const setCoverError = (error: string) => setError('cover', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const clearCoverError = () => clearErrors('cover');

  const handleProviderClick = (provider: TOauthProvider | TMiscProvider) => {
    if (
      provider.type !== MiscProviderType.CREDENTIALS &&
      provider.type !== MiscProviderType.EMAIL &&
      provider.client_id === selectedClient.client_id
    ) {
      setSelectedProvider(provider);
      setProviderModalOpen(true);
    }
  };

  return (
    <>
      {!openedFromMenu && (
        <Typography className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}>
          Настройки приложения
        </Typography>
      )}
      <Typography
        className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
      >
        Основная информация
      </Typography>
      <Typography
        className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
      >
        Название приложения
      </Typography>
      <TextField
        {...register('name', {
          required: true,
          onBlur: (event: FocusEvent<HTMLInputElement>) => {
            setValue('name', event.target.value.trim());
          },
          onChange: () => {
            if (errors.name) clearErrors('name');
          },
        })}
        className="custom"
        FormHelperTextProps={{
          className: clsx('text-14', 'color-858BA0'),
        }}
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : ''}
        fullWidth
        variant="standard"
        disabled={isAdminClient}
      />
      <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
        Имя приложения, отображаемое пользователям
      </Typography>
      {!isAdminClient && (
        <>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Описание приложения
          </Typography>
          <TextField
            {...register('description', {
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length > 255) {
                  setError('description', {
                    message: 'Невозможно ввести более 255 символов',
                    type: 'validate',
                  });
                  setValue('description', event.target.value.slice(0, 255));
                } else if (errors.description) {
                  clearErrors('description');
                }
              },
            })}
            className="custom"
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ''}
            fullWidth
            variant="standard"
            multiline
          />
          <DescriptionField control={control} />
        </>
      )}
      <div className={styles['upload-file']}>
        <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
          Логотип приложения
        </Typography>
        <UploadAndDisplayImage
          maxImageSize={1}
          imgSrc={avatarSrc}
          setImgSrc={setAvatarSrc}
          setAvatarError={setAvatarError}
          clearAvatarError={clearAvatarError}
          defaultValue={selectedClient?.avatar || null}
          componentName={'edit-client'}
          setAvatarValue={setAvatarValue}
          DefaultIcon={<AppIcon fill="#ced0d9" />}
          pathToAvatar={isAdminClient ? LOGO_URL.replace(`${BACKEND_URL}/`, '') : null}
          disabled={isAdminClient}
        />
        {errors.avatar && (
          <Typography className={clsx('text-14', styles['input-error'])}>
            {errors.avatar.message}
          </Typography>
        )}
        <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
          Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
        </Typography>
        <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
          Обложка приложения
        </Typography>
        <UploadAndDisplayImage
          maxImageSize={5}
          setAvatarError={setCoverError}
          imgSrc={coverSrc}
          setImgSrc={setCoverSrc}
          clearAvatarError={clearCoverError}
          defaultValue={selectedClient?.cover || null}
          componentName={'edit-client'}
          setAvatarValue={setCoverValue}
          pathToAvatar={null}
        />
        {errors.cover && (
          <Typography className={clsx('text-14', styles['input-error'])}>
            {errors.cover.message}
          </Typography>
        )}
        <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
          Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 5 МБ.
        </Typography>
      </div>
      <div className={styles.divider} />
      <Typography
        className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
      >
        Параметры приложения
      </Typography>
      <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
        Идентификатор (Client_id)
      </Typography>
      <TextField
        className="custom"
        error={!!errors.client_id}
        helperText={errors.client_id ? errors.client_id.message : ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                arrow
                title="Копировать"
                classes={{
                  tooltip: styles['input-tooltip'],
                  arrow: styles['input-tooltip-arrow'],
                }}
              >
                <Button
                  className={styles['input-adornment-button']}
                  onClick={() => {
                    if (selectedClient) navigator.clipboard.writeText(selectedClient.client_id);
                  }}
                >
                  <CopyIcon />
                </Button>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          className: clsx('text-14', 'color-858BA0'),
        }}
        fullWidth
        variant="standard"
        {...register('client_id', {
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length > 255) {
              setError('client_id', {
                message: 'Невозможно ввести более 255 символов',
                type: 'validate',
              });
              setValue('client_id', event.target.value.slice(0, 255));
            } else if (errors.client_id) {
              clearErrors('client_id');
            }
          },
        })}
        disabled={isAdminClient}
      />
      <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
        Уникальный идентификатор приложения
      </Typography>
      <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
        Секретный ключ (client_secret)
      </Typography>
      <PasswordTextfield
        value={watchClientSecret}
        className="custom"
        error={!!errors.client_secret}
        helperText={errors.client_secret ? errors.client_secret.message : ''}
        FormHelperTextProps={{
          className: clsx('text-14', 'color-858BA0'),
        }}
        fullWidth
        variant="standard"
        id="copy"
        {...register('client_secret', {
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length > 255) {
              setError('client_secret', {
                message: 'Невозможно ввести более 255 символов',
                type: 'validate',
              });
              setValue('client_secret', event.target.value.slice(0, 255));
            } else if (errors.client_secret) {
              clearErrors('client_secret');
            }
          },
        })}
        disabled={isAdminClient}
      />
      <Button variant="custom2" className={clsx('text-14', styles['input-subtitle'])}>
        Обновить
      </Button>
      <Typography
        className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
      >
        Адрес приложения
      </Typography>
      <TextField
        {...register('domain', {
          onChange: () => {
            if (errors.domain) clearErrors('domain');
          },
        })}
        className="custom"
        FormHelperTextProps={{
          className: clsx('text-14', 'color-858BA0'),
        }}
        error={!!errors.domain}
        helperText={errors.domain ? errors.domain.message : ''}
        fullWidth
        variant="standard"
        disabled={isAdminClient}
      />
      <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
        Адрес приложения в формате «протокол://доменное имя:порт»
      </Typography>
      <>
        {redirectUris.map((uri, index) => {
          return (
            <div key={uri.id}>
              <Typography
                className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
              >
                Возвратный URL #{index + 1} (Redirect_uri)
              </Typography>
              <div className={styles['field-item']}>
                <TextField
                  {...register(`redirect_uris.${index}.value`, {
                    onChange: () => {
                      if (errors.redirect_uris?.[index])
                        clearErrors(`redirect_uris.${index}.value`);
                    },
                  })}
                  className={clsx('custom', styles['add-text-field'])}
                  FormHelperTextProps={{
                    className: clsx('text-14', 'color-858BA0'),
                  }}
                  onBlur={() => {
                    if (getValues('redirect_uris').every((uri) => !uri.value))
                      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
                    else {
                      clearErrors(`redirect_uris.0.value`);
                      trigger(`redirect_uris.${index}.value`);
                    }
                  }}
                  variant="standard"
                  error={!!errors.redirect_uris?.[index]}
                  helperText={
                    errors.redirect_uris ? errors?.redirect_uris?.[index]?.value?.message : ''
                  }
                  disabled={isAdminClient}
                />

                {redirectUris.length > 1 ? (
                  <Button
                    variant="custom"
                    color="secondary"
                    onClick={() => redirectRemove(index)}
                    className={clsx(styles['delete-button'])}
                  >
                    Удалить
                  </Button>
                ) : (
                  <RedirectUrisField
                    control={control}
                    onClick={() => redirectAppend({ value: '', name: '' })}
                    className={styles['add-button']}
                    name="redirect_uris"
                    disabled={isAdminClient}
                  />
                )}
              </div>
              {index === 0 && (
                <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
                  Адрес, на который пользователь переадресовывается после авторизации
                </Typography>
              )}
            </div>
          );
        })}
        {redirectUris.length > 1 && (
          <RedirectUrisField
            control={control}
            onClick={() => redirectAppend({ value: '', name: '' })}
            className={clsx(styles['add-button'], styles['add-button-bottom'])}
            name="redirect_uris"
          />
        )}
      </>
      <>
        {logoutUris.map((uri, index) => {
          return (
            <div key={uri.id}>
              <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                URL выхода из системы #{index + 1} (post_logout_redirect_uri)
              </Typography>
              <div className={styles['field-item']}>
                <TextField
                  {...register(`post_logout_redirect_uris.${index}.value`, {
                    onChange: () => {
                      if (errors.post_logout_redirect_uris?.[index])
                        clearErrors(`post_logout_redirect_uris.${index}.value`);
                    },
                  })}
                  className={clsx('custom', styles['add-text-field'])}
                  FormHelperTextProps={{
                    className: clsx('text-14', 'color-858BA0', styles['helper-text']),
                  }}
                  error={!!errors.post_logout_redirect_uris?.[index]}
                  helperText={
                    errors.post_logout_redirect_uris
                      ? errors?.post_logout_redirect_uris?.[index]?.value?.message
                      : ''
                  }
                  variant="standard"
                  disabled={isAdminClient}
                />
                {logoutUris.length > 1 ? (
                  <Button
                    variant="custom"
                    color="secondary"
                    onClick={() => logoutRemove(index)}
                    className={clsx(styles['delete-button'])}
                  >
                    Удалить
                  </Button>
                ) : (
                  <RedirectUrisField
                    control={control}
                    onClick={() => logoutAppend({ value: '', name: '' })}
                    className={styles['add-button']}
                    name="post_logout_redirect_uris"
                    disabled={isAdminClient}
                  />
                )}
              </div>
              {index === 0 && (
                <Typography
                  className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}
                  style={{ width: '85%' }}
                >
                  Адрес, на который переадресовывается пользователь после выхода. Если значение не
                  указано, то используется «Возвратный URL»
                </Typography>
              )}
            </div>
          );
        })}
        {logoutUris.length > 1 && (
          <RedirectUrisField
            control={control}
            onClick={() => logoutAppend({ value: '', name: '' })}
            name="post_logout_redirect_uris"
          />
        )}
      </>
      {/* #371
        <div className={styles['switch-wrapper']}>
          <Typography className={clsx('text-14', 'color-0B1641')}>
            Ограничить время действия токена обновления (refresh_token)
          </Typography>
          <Switch
            value={showRefreshTokenInput}
            onChange={() => setShowRefreshTokenInput((shown) => !shown)}
            disableRipple
          />
        </div>

        {showRefreshTokenInput && (
          <>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Время действия токена обновления (refresh_token)
            </Typography>
            <div style={{ position: 'relative' }}>
              <TextField
                {...register('refresh_token_ttl', {
                  onChange: () => {
                    if (errors.refresh_token_ttl) clearErrors('refresh_token_ttl');
                  },
                })}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key.toLowerCase() === 'e') e.preventDefault();
                }}
                type="number"
                inputProps={{ className: styles.input }}
                className={clsx('custom', styles['token-textfield'])}
                InputProps={{
                  className: styles['token-input'],
                }}
                FormHelperTextProps={{
                  className: clsx('text-14', 'color-858BA0'),
                }}
                error={!!errors.refresh_token_ttl}
                helperText={errors.refresh_token_ttl ? errors.refresh_token_ttl.message : ''}
                fullWidth
                variant="standard"
              />
              <Typography
                className={clsx('text-14', 'color-858BA0')}
                style={{ position: 'absolute', right: 40, top: 10 }}
              >
                секунд
              </Typography>
            </div>
          </>
        )}
        <Typography
          className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
        >
          Время действия авторизационного токена (access_token)
        </Typography>
        <div style={{ position: 'relative' }}>
          <TextField
            {...register('access_token_ttl', {
              onChange: () => {
                if (errors.access_token_ttl) clearErrors('access_token_ttl');
              },
            })}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key.toLowerCase() === 'e') e.preventDefault();
            }}
            type="number"
            className={clsx('custom', styles['token-textfield'])}
            InputProps={{
              className: styles['token-input'],
            }}
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0', styles['helper-token']),
            }}
            fullWidth
            variant="standard"
          />
          <Typography
            className={clsx('text-14', 'color-858BA0')}
            style={{ position: 'absolute', right: 40, top: 10 }}
          >
            секунд
          </Typography>
        </div>
        {!!errors.access_token_ttl && (
          <Typography className={clsx('text-14', styles['input-error'])}>
            {errors.access_token_ttl.message}
          </Typography>
        )} */}
      <div className={styles.divider} />
      <Typography
        className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
      >
        Способы входа
      </Typography>
      <Button
        variant="custom2"
        className={styles['add-button']}
        startIcon={<AddIcon className={styles['add-icon']} />}
        onClick={() => setProviderModalOpen(true)}
      >
        Добавить способ входа
      </Button>
      <div className={styles.providers}>
        {providersLoading &&
          [null, null].map((_, index) => (
            <div key={index} className={styles.provider}>
              <IconWrapper>
                <Skeleton width={20} height={30} />
              </IconWrapper>
              <div>
                <Typography className={clsx('text-14', 'color-0B1641')}>
                  <Skeleton />
                </Typography>
                <Typography className={clsx('text-12', 'color-858BA0')}>OAuth 2</Typography>
              </div>
              <IconButton className={styles['icon-button-wrapper']}>
                <CloseIcon className={styles['icon-button']} />
              </IconButton>
            </div>
          ))}
        {providers
          ?.filter((provider) => provider.is_active)
          .map((provider) => (
            <div
              key={provider.id + provider.type}
              className={styles.provider}
              onClick={() => handleProviderClick(provider)}
            >
              <div
                style={{
                  backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                }}
                className={styles['provider-icon-wrapper']}
              >
                {!provider.avatar && <IdIcon />}
              </div>
              <div className={styles['provider-name-wrapper']}>
                <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                  {provider.name}
                </Typography>
                <Typography className={clsx('text-12', 'color-858BA0')}>
                  {getProviderTitleByType(provider.type)}
                </Typography>
              </div>
              {providers?.filter(
                (provider) => provider.is_active && provider.type !== MiscProviderType.CREDENTIALS,
              ).length >= 1 && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deactivateProvider({
                      body: {
                        providers: [
                          {
                            type: provider.type,
                            id: String(provider.id),
                            client_id: provider.client_id,
                          },
                        ],
                      },
                      client_id: selectedClient.client_id,
                    });
                  }}
                  className={styles['icon-button-wrapper']}
                >
                  <CloseIcon className={styles['icon-button']} />
                </IconButton>
              )}
            </div>
          ))}
      </div>
    </>
  );
};
